import * as Sentry from "@sentry/react";
const ErrorCatch = (name, error, state) => {
	// let user = state?.walletAddress !== null ? state?.walletAddress : localStorage.getItem("userName") !== undefined ? localStorage.getItem("userName") : "Public";
	// if (user !== undefined) {
	// 	Sentry.setUser({ username: user });
	// }
	Sentry.setExtra("error", error);
	Sentry.captureException(new Error(name), {
		tags: {
			section: window.location.pathname,
		},
	});
};

export default ErrorCatch;
