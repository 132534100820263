import { DataTable, Button } from "primereact";
import { Column } from "primereact/column";
import styles from "./index.module.scss";
export default function BPointsLeaderboard(props) {
	const header = (
		<div className={styles.header}>
			<span className={styles.title}>B Points Leaderboard</span>
			<Button
				className="p-button-text p-0 w-1 tooltip-button"
				icon="pi pi-info-circle"
				tooltip="Compare your total points with others and view potential node rewards based on your rank."
				tooltipOptions={{ position: "top", className: "tooltip-help" }}
			/>
		</div>
	);
	return (
		<div className={styles.main}>
			<DataTable
				loading={props?.initialLoading}
				scrollable
				frozenValue={props.usersStakingData.loggedInUser}
				value={props.usersStakingData.allUsers}
				header={header}
				paginator
				rows={25}
				rowsPerPageOptions={[5, 10, 25, 50]}
			>
				<Column field="rank" header="Rank" style={{ maxWidth: "70px" }} />
				<Column style={{ minWidth: "200px" }} field="username" header="User"></Column>
				{props?.activeLeaderboardTab === 0 && (
					<Column
						field="multiplier"
						className="flex justify-content-center gap-1 w-full"
						header="Node Rewards"
						body={(e) => {
							return (
								<div className="flex justify-content-center gap-1">
									{e.node_rewards || 0}
									<img src="/assets/icons/pointfarm/bitcoin-icons.png" alt="bitcoin-icons.png" height="20px" />
								</div>
							);
						}}
					/>
				)}
				{props?.activeLeaderboardTab === 0 && (
					<Column field="points" className="flex justify-content-end gap-1 w-full" header="Total Points" body={(e) => Number(e.points).toLocaleString(undefined, { minimumFractionDigits: 4 })} />
				)}
				{props?.activeLeaderboardTab === 1 && (
					<Column field="points" className="flex justify-content-end gap-1 w-full" header="Total Points" body={(e) => Number(e.v2Points).toLocaleString(undefined, { minimumFractionDigits: 4 })} />
				)}
				{props?.activeLeaderboardTab === 2 && (
					<Column field="points" className="flex justify-content-end gap-1 w-full" header="Total Points" body={(e) => Number(e.v3Points).toLocaleString(undefined, { minimumFractionDigits: 4 })} />
				)}
			</DataTable>
		</div>
	);
}
