import { useState } from "react";
import { Skeleton } from "primereact/skeleton";
import styles from "./index.module.scss";
export default function Statistics() {
	const [isLoading, setIsLoading] = useState(true);

	const handleLoad = () => {
		setIsLoading(false);
	};
	return (
		<div className={styles.rightPanel}>
			<h1>
				$Bull Token Staking Dashboard
				<img src="/assets/icons/rewards/rewards_icon.png" alt="icon" />
			</h1>
			<h2>$Bull Token Staking Dashboard - Real-time statistics and historical data visualization</h2>
			<div>
				{isLoading && <Skeleton className={styles.iFrameLoading}></Skeleton>}

				<iframe
					title="$Bull Token Staking Dashboard"
					onLoad={handleLoad}
					src="https://bull-token-staking-visualizer.replit.app/"
					className={styles.iframe}
					style={{ display: isLoading ? "none" : "block" }}
				/>
			</div>
		</div>
	);
}
