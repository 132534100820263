import styles from "./index.module.scss";
import { Button, Menubar } from "primereact";
import { AiOutlineLogout } from "react-icons/ai";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function NavBar() {
	const { userDetails, userAccountDetails } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const items = [
		{
			label: "Wallet",
			icon: <img src="/assets/icons/Wallet.png" alt="icon" style={{ height: "15px" }} />,
			command: () => {
				navigate("/");
			},
		},
		{
			label: "Settings",
			icon: <img src="/assets/icons/settings.png" alt="icon" style={{ height: "15px" }} />,
			command: () => {
				navigate("/settings");
			},
		},
		{
			label: "Games",
			icon: <img src="/assets/icons/Games_active.png" alt="icon" style={{ height: "15px" }} />,
			command: () => {
				navigate("/games");
			},
		},
		// {
		// 	label: "Statistics",
		// 	icon: <img src="/assets/icons/settings.png" alt="icon" style={{ height: "15px" }} />,
		// 	command: () => {
		// 		navigate("/statistics");
		// 	},
		// },
	];

	const signout = async () => {
		await userAccountDetails?.Swallet?.disconnect();
		await userAccountDetails?.personalWallet?.disconnect();
		dispatch({ type: "LOAD_USER_DETAILS", payload: [] });
		dispatch({ type: "LOAD_USER_WALLET_BALANCE", payload: {} });
		localStorage.removeItem("beyond_user");
		navigate("/");
	};
	return (
		<>
			<div className={styles.main}>
				<div className="container">
					<img src="/assets/NavBar/logo.png" alt="logo" className={styles.logo} onClick={() => navigate("/")} />
					{/* {userDetails?.username && (
            <div className={styles.rightSection}>
              <div className={styles.coin}>
                <img src="/assets/NavBar/coin.png" className={styles.bullIcon} alt="coin" />
                <span className={styles.balance}>$0</span>
                <span className={styles.symbol}>$B</span>
              </div>
              <Button
                iconPos="right"
                className={styles.walletAddress}
                label={dispatch(allActions.authActions.splitWalletAddress(userAccountDetails?.smartAccount?.address))}
                icon="pi pi-angle-down"
                onClick={(event) => menu.current.toggle(event)}
                aria-haspopup
                aria-controls="overlay_tmenu"
              />
            </div>
          )} */}
					{window.innerWidth < 768 && userDetails?.username ? (
						<Menubar model={items} />
					) : (
						<>{userDetails?.username && <Button label="Sign out" className="p-button-danger" onClick={() => signout()} />}</>
					)}

					{/* {userDetails?.username && <Button label="Sign out" className="p-button-danger" onClick={() => signout()} />} */}
				</div>
			</div>
		</>
	);
}
