import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button } from "primereact";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios/axios";
import configuration from "Config";
import { useDispatch, useSelector } from "react-redux";
import ErrorCatch from "Sentry";

export default function Login() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false);
	const [isAccountAlredyLinked, setIsAccountAlredyLinked] = useState(false);
	const { newAuthFlowUserDetails } = useSelector((state) => state.auth);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email: newAuthFlowUserDetails?.email || "",
		},
	});
	const params = useParams();
	const searchParams = useSearchParams();
	const dispatch = useDispatch();

	useEffect(() => {
		const parseParams = async () => {
			let { loginType } = params;
			if (loginType) {
				let searchParam = searchParams[0];

				try {
					let {
						response: { data },
					} = await axios.post(`/auth/${loginType}CallBack`, {
						code: searchParam.get("code"),
						redirectUri: `${configuration.AUTH_REDIRECT_URL}/${loginType}`,
					});
					if (window?.opener) {
						const message = { target: "Passport", data };
						window?.opener?.postMessage(message, "*");
						window.close();
					} else {
						localStorage.setItem("beyond_user", JSON.stringify(data));
						dispatch({ type: "LOAD_USER_DETAILS", payload: { status: true } });
						let isGameLogin = localStorage.getItem("isGameLogin");
						if (isGameLogin === "true" || isGameLogin === true) {
							localStorage.removeItem("isGameLogin");
							navigate("/gamelogin/launch");
						} else {
							navigate("/");
						}
					}
				} catch (error) {
					let email = error?.error?.email ?? undefined;
					if (email) {
						dispatch({
							type: "addNewAuthFlowUserDetails",
							payload: {
								integrationId: error?.error?.integrationId,
								oauthCode: searchParam.get("code"),
								redirectUri: `${configuration.AUTH_REDIRECT_URL}/${loginType}`,
								oauthEmail: email,
								email: email,
								provider: loginType?.toUpperCase(),
							},
						});
						navigate("/auth/signup/socialconnect");
					} else {
						navigate("/auth");
					}
				}
			} else {
				setIsPageLoaded(true);
			}
		};
		parseParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (form) => {
		setIsLoading(true);
		try {
			if (newAuthFlowUserDetails?.oauthCode) {
				form["integrationId"] = newAuthFlowUserDetails?.integrationId;
				form["oauthProvider"] = newAuthFlowUserDetails?.provider;
				form["signInType"] = "oauth_link";
			} else {
				form["signInType"] = "email";
			}
			let {
				response: { data },
			} = await axios.post("/auth/signIn", form);
			if (data?.errorMessage) {
				setIsAccountAlredyLinked(true);
			} else {
				setIsAccountAlredyLinked(false);
				if (window?.opener) {
					const message = { target: "Passport", data };
					window?.opener?.postMessage(message, "*");
					window.close();
				} else {
					let isGameLogin = localStorage.getItem("isGameLogin");
					localStorage.setItem("beyond_user", JSON.stringify(data));
					if (isGameLogin === "true" || isGameLogin === true) {
						localStorage.removeItem("isGameLogin");
						navigate("/gamelogin/launch");
					} else {
						dispatch({ type: "LOAD_USER_DETAILS", payload: { status: true } });
						navigate("/");
					}
				}
			}
		} catch (error) {
			ErrorCatch("LogIn.submitForm", error, "Public");
			if (error?.error?.message === "Email and Password do not match.") {
				setError("password", { type: "custom", message: "Invalid password" });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			{!isPageLoaded ? (
				<div className={styles.loader}>
					<i className="pi pi-spin pi-spinner" />
				</div>
			) : (
				<div className={main.container}>
					<div className={main.main}>
						<div className="mobileHeader">
							<div className="leftSide">
								<i className="pi pi-arrow-left backIcon" onClick={() => navigate(-1)} />
							</div>
							<div className="rightSide">
								<img src="/assets/icons/logo_plain.png" alt="logo" />
							</div>
						</div>
						<img src="/assets/banners/login_banner.png" alt="banner" className={main.banner} />
						<div className={styles.start}>
							<div className={main.title}>Sign in to your Beyond Account</div>
							<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
								<span className={styles.inputField}>
									<span className={styles.tag}>Email</span>
									<InputText
										id="email"
										readOnly
										type="email"
										className={errors.email ? `${styles.emailField} p-error` : styles.emailField}
										placeholder="i.e. example@mail.com"
										{...register("email")}
									/>
									{errors.email && <span className={`p-error ${styles.errorClass}`}>{errors.email.message}</span>}
								</span>

								<span className={`p-input-icon-right ${styles.inputField}`}>
									<i className="pi pi-eye" onClick={() => setShowPassword(!showPassword)} />
									<InputText
										id="password"
										placeholder="Password"
										type={showPassword ? "text" : "password"}
										// className={errors.password ? `${styles.emailField} p-error` : styles.emailField}
										{...register("password", {
											required: {
												value: true,
												message: "Password is required",
											},
										})}
									/>
								</span>
								{errors.password && <span className={`p-error ${styles.errorClass}`}>{errors.password.message}</span>}
								<NavLink to="/auth/resetpassword" className={styles.forgotPassword}>
									Forgot your password?
								</NavLink>
								{isAccountAlredyLinked && (
									<div className={styles.accountAlreadyLinkedMessage}>
										<img src="/assets/icons/info_blue.png" alt="info" className={styles.infoicon} />
										<p>
											Your <span>Beyond Account</span> is already linked with a {newAuthFlowUserDetails?.provider} account
										</p>
									</div>
								)}
								<Button type="submit" loading={isLoading} label="Sign In" className={styles.continueButton} />
							</form>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
