import styles from "./index.module.scss";
import { Button, Dialog, Skeleton } from "primereact";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios/axios";
import { useWeb3ModalAccount, useWeb3ModalProvider, useWeb3ModalEvents, useWeb3Modal, useDisconnect } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { Messages } from "primereact/messages";
import { useDispatch, useSelector } from "react-redux";
import allActions from "redux/actions";
import configuration from "Config";
import base58 from "bs58";
import { isMobile } from "react-device-detect";
import { useWallet } from "@solana/wallet-adapter-react";
// import TelegramLoginButton from "react-telegram-login";
import { LoginButton } from "@telegram-auth/react";

export default function Home() {
	const [telegramToken, setTelegramToken] = useState(null);
	useEffect(() => {
		const requestTelegramToken = async () => {
			let a = await axios.get(`/auth/requestTelegramToken`);
			setTelegramToken(a?.response?.token);
		};
		requestTelegramToken();
	}, []);
	// eslint-disable-next-line no-unused-vars
	const { select, wallets, publicKey, disconnect: solDisconnect, connecting: solConnecting, signMessage: solanaSignMessage, connected } = useWallet();
	const [showSolanaWallets, setShowSolanaWallets] = useState(false);
	// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	const [showDisconnectModal, setShowDisconnectModal] = useState(false);
	const dialogRef = useRef(null);
	const { open } = useWeb3Modal();
	const { walletProvider } = useWeb3ModalProvider();
	const { address } = useWeb3ModalAccount();
	const { disconnect } = useDisconnect();
	const { data: W3Events } = useWeb3ModalEvents();
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const { userDetails } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [disconnectType, setDisconnectType] = useState("");

	const searchParams = useSearchParams();
	const params = useParams();

	const msgs = useRef(null);

	const connectBackpackWallet = async () => {
		console.log(`https://backpack.app/ul/v1/browse/${encodeURIComponent(process.env.REACT_APP_PASSPORT_URL)}/?ref=${encodeURIComponent(process.env.REACT_APP_PASSPORT_URL)}`);
		if (isMobile && typeof window?.backpack === "undefined") {
			window.open(`https://backpack.app/ul/v1/browse/${encodeURIComponent(process.env.REACT_APP_PASSPORT_URL)}/?ref=${encodeURIComponent(process.env.REACT_APP_PASSPORT_URL)}`);
		}

		if (typeof window?.backpack === "undefined") {
			console.warn("Backpack wallet is not installed.");
			msgs.current.show([
				{
					severity: "error",
					// summary: 'Backpack Wallet Missing',
					detail: "Backpack wallet is not installed. Redirecting to the Backpack site for installation.",
					sticky: false,
					closable: true,
				},
			]);

			setTimeout(() => {
				window.open("https://backpack.app/", "_blank");
			}, 1000); // 1000 ms = 1 second

			setIsLoading(false);
			return;
		}
		try {
			setIsLoading(true);
			select("Backpack");

			const getSignature = async () => {
				let {
					response: {
						data: { eclipseWallet },
					},
				} = await axios.get("/auth/user/details");
				if (eclipseWallet) {
					return;
				}
				setIsLoading(true);
				try {
					let {
						response: { message },
					} = await axios.get(`/auth/request/wallet/link?walletType=eclipseWallet&walletAddress=${publicKey.toBase58()}`);
					const encodedMessage = new TextEncoder().encode(message);
					const signedMessage = await solanaSignMessage(encodedMessage);
					const signature = base58.encode(signedMessage);
					await axios.post("/auth/verify/wallet/link", { walletType: "eclipseWallet", walletAddress: publicKey.toBase58(), signature });
					dispatch(allActions.authActions.getUserDetails());
				} catch (error) {
					console.error(error);
					msgs.current.show([{ severity: "error", summary: "", detail: `This ${publicKey?.toBase58()} is already linked with other account.`, sticky: false, closable: true }]);
					if (address) {
						disconnect();
					}
				} finally {
					setIsLoading(false);
				}
			};
			if (publicKey) {
				getSignature();
			}
		} catch (error) {
			console.error("Error connecting Backpack wallet:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const getSignature = async () => {
			setIsLoading(true);
			try {
				const web3Provider = new ethers.providers.Web3Provider(walletProvider);
				const signer = web3Provider.getSigner();
				let {
					response: { message },
				} = await axios.get(`/auth/request/wallet/link?walletType=eth&walletAddress=${address} `);
				const signature = await signer?.signMessage(message);
				await axios.post("/auth/verify/wallet/link", { walletType: "eth", walletAddress: address, signature });
				dispatch(allActions.authActions.getUserDetails());
			} catch (error) {
				console.error(error);
				msgs.current.show([{ severity: "error", summary: "", detail: `This ${address} is already linked with other account.`, sticky: false, closable: true }]);
				if (address) {
					disconnect();
				}
			} finally {
				setIsLoading(false);
			}
		};
		const changeEvent = async () => {
			let {
				response: {
					data: { ethAddress },
				},
			} = await axios.get("/auth/user/details");
			if (!ethAddress) {
				if (W3Events?.event === "MODAL_CLOSE") {
					setIsLoading(false);
					if (address) {
						getSignature();
					}
				}
				if (W3Events?.event === "CONNECT_SUCCESS") {
					if (address) {
						getSignature();
					}
				}
			}
		};
		changeEvent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [W3Events.event, address]);

	useEffect(() => {
		// const getSignature = async () => {
		//     let { response: { data: { eclipseWallet } } } = await axios.get("/auth/user/details");
		//     if (eclipseWallet) {
		//         return;
		//     }
		//     setIsLoading(true);
		//     try {
		//         let { response: { message } } = await axios.get(`/ auth / request / wallet / link ? walletType = eclipseWallet & walletAddress=${ publicKey.toBase58() } `);
		//         const encodedMessage = new TextEncoder().encode(message);
		//         const signedMessage = await solanaSignMessage(encodedMessage);
		//         const signature = base58.encode(signedMessage);
		//         await axios.post("/auth/verify/wallet/link", { walletType: "eclipseWallet", walletAddress: publicKey.toBase58(), signature });
		//         dispatch(allActions.authActions.getUserDetails());
		//     } catch (error) {
		//         console.error(error);
		//         msgs.current.show([
		//             { severity: 'error', summary: '', detail: `This ${ publicKey?.toBase58() } is already linked with other account.`, sticky: false, closable: true },
		//         ]);
		//         if (address) {
		//             disconnect()
		//         }
		//     } finally {
		//         setIsLoading(false);
		//     }
		// };
		// if (publicKey) {
		//     getSignature();
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publicKey]);

	useEffect(() => {
		let { type } = params;
		const linkSocialAccount = async () => {
			let searchParam = searchParams[0];
			try {
				await axios.post("/auth/oauth/integrate", {
					code: searchParam.get("code"),
					redirectUri: `${process.env.REACT_APP_AUTH_INTEGRATE_REDIRECT_URL}/${type}`,
					provider: type?.toUpperCase(),
				});
				navigate("/");
				dispatch(allActions.authActions.getUserDetails());
			} catch (error) {
				if (error?.error?.name === "IntegrationError") {
					msgs.current.show([{ severity: "error", summary: "", detail: `This ${type} account is already linked with other account.`, sticky: false, closable: true }]);
				}
				navigate("/");
				console.error(error);
			}
		};
		if (type) {
			linkSocialAccount();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const googleConnect = async () => {
		try {
			window.location.replace(
				`https://accounts.google.com/o/oauth2/v2/auth?client_id=${configuration.GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_INTEGRATE_REDIRECT_URL}/google&response_type=code&scope=profile email`
			);
		} catch (error) {
			console.error(error);
		}
	};

	const facebookConnect = async () => {
		try {
			window.location.replace(
				`https://www.facebook.com/v13.0/dialog/oauth?client_id=${configuration.FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_INTEGRATE_REDIRECT_URL}/facebook&scope=email`
			);
		} catch (error) {
			console.error(error);
		}
	};

	const modalDisconnectButtonCllicked = async () => {
		try {
			if (disconnectType === "wallet") {
				//disconnect();
			} else {
				setIsLoading(true);
				await axios.get(`/auth/oauth/unlink?provider=${disconnectType}`);
				msgs.current.show([{ severity: "success", summary: "", detail: `You have successfully unlinked your ${disconnectType} account.`, sticky: false, closable: true }]);
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(allActions.authActions.getUserDetails());
			setShowDisconnectModal(false);
			setIsLoading(false);
		}
	};

	const checkIsSocialConnected = (type) => {
		return userDetails?.integrationIds?.findIndex((item) => item.integrationType === type) > -1 ? true : false;
	};

	const getSocialConnectedUsername = (type) => {
		let data = userDetails?.integrationIds?.filter((item) => item.integrationType === type);
		return data.length > 0 ? data[0] : data;
	};

	const splitWalletAddress = (walletAddress) => {
		if (walletAddress) {
			return walletAddress.substring(0, 7) + "..." + walletAddress.substring(walletAddress.length - 5, walletAddress.length);
		}
	};

	return (
		<>
			<Messages ref={msgs} className="messageBox"></Messages>
			<Dialog
				showHeader={false}
				contentClassName={styles.content}
				baseZIndex="100000"
				appendTo={dialogRef.current}
				className={styles.mainDialog}
				header="Header"
				visible={showSolanaWallets}
				style={{ width: "300px" }}
				breakpoints={{ "960px": "95vw" }}
				onHide={() => setShowSolanaWallets(false)}
			>
				<div className="flex flex-column gap-3">
					{wallets.filter((wallet) => wallet.readyState === "Installed").length > 0 ? (
						wallets
							.filter((wallet) => wallet.readyState === "Installed")
							.map((wallet) => (
								<Button
									key={wallet.adapter.name}
									onClick={() => {
										setShowSolanaWallets(false);
										select(wallet.adapter.name);
									}}
									icon={<img src={wallet.adapter.icon} alt={wallet.adapter.name} height={20} width={20} />}
									style={{
										background: "#353740 !important",
									}}
									label={wallet.adapter.name}
								/>
							))
					) : (
						<div>No wallet found. Please download a supported Solana wallet</div>
					)}
				</div>
			</Dialog>
			<Dialog
				showHeader={false}
				contentClassName={styles.content}
				baseZIndex="100000"
				appendTo={dialogRef.current}
				className={styles.mainDialog}
				header="Header"
				visible={showDisconnectModal}
				style={{ width: "30vw" }}
				breakpoints={{ "960px": "95vw" }}
				onHide={() => setShowDisconnectModal(false)}
			>
				<img src="/assets/banners/disconnectModal.png" alt="banner" className={styles.banner} />
				<h1>Disconnect Account</h1>
				<p>Disconnecting this account means you can no longer use it to log in. Are you sure?</p>
				<div className={styles.buttons}>
					<Button loading={isLoading} label="Disconnect" className="p-button-danger" onClick={() => modalDisconnectButtonCllicked()} />
					<Button label="Cancel" className="p-button-secondary p-button-outlined" onClick={() => setShowDisconnectModal(false)} />
				</div>
			</Dialog>

			{userDetails?.username ? (
				<>
					<div className={styles.rightPanel}>
						<h1>
							Connected Socials and Wallets
							<img src="/assets/icons/blue_heading.png" alt="icon" />
						</h1>
						<h2>View and control your linked socials and wallets.</h2>
						{/* <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="beyounddev_bot" /> */}
						{/* <LoginButton
							botUsername="beyounddev_bot"
							authCallbackUrl={`https://dev-api.beyondnetwork.xyz/auth/telegramCallBack?token=${telegramToken}`}
							buttonSize="large" // "large" | "medium" | "small"
							cornerRadius={5} // 0 - 20
							showAvatar={false} // true | false
							lang="en"
						/> */}

						<div className={styles.box}>
							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/beyond_white.png" alt="metamask" className={styles.icon} />
									<div>
										Your Email
										<p>{userDetails?.email}</p>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.box}>
							<div className={styles.heading}>Connect Wallet</div>
							{/* <div className={styles.item}>
                                <div className={styles.left}>
                                    <img src="/assets/icons/metamask.png" alt="metamask" className={styles.icon} />
                                    <div>
                                        Metamask
                                        <p>{isMobile ? "Connect to Metamask" : "Connect your wallet using Metamask"}</p>
                                    </div>
                                </div>
                                <Button label="Coming Soon" className={styles.connectButton} disabled />
                                 <Button label="Connected" icon="pi pi-circle-fill" className={styles.connectedButton} onClick={() => setShowDisconnectModal(true)} /> 
                            </div> */}

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/walletconnect.png" alt="metamask" className={styles.icon} />
									<div>
										WalletConnect
										<p>{userDetails?.ethAddress ? splitWalletAddress(userDetails?.ethAddress) : isMobile ? "Connect your wallet" : "Connect your wallet using Wallet connect"}</p>
									</div>
								</div>
								{userDetails?.ethAddress ? (
									<Button disabled label="Linked" icon="pi pi-circle-fill" className={styles.connectedButton} />
								) : (
									// <Button label="Disconnect" loading={isLoading} className={styles.disconnectButton} disabled />
									<Button
										label="Link"
										loading={isLoading}
										className={styles.connectButton}
										onClick={() => {
											setIsLoading(true);
											open();
										}}
									/>
								)}
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/eclipse.png" alt="solana" className={styles.icon} />
									<div>
										Eclipse
										<p>{userDetails?.eclipseWallet ? splitWalletAddress(userDetails?.eclipseWallet) : isMobile ? "Connect your wallet" : "Connect your wallet using Backpack"}</p>
									</div>
								</div>
								{userDetails?.eclipseWallet ? (
									<Button disabled label="Linked" icon="pi pi-circle-fill" className={styles.connectedButton} />
								) : (
									<>
										<Button label="Link" loading={isLoading} className={styles.connectButton} onClick={connectBackpackWallet} />
									</>
								)}
							</div>
						</div>

						<div className={styles.box}>
							<div className={styles.heading}>Connect Socials</div>
							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/google.png" alt="google" className={styles.icon} />
									<div>
										Google
										<p className={styles.dotText}>
											{checkIsSocialConnected("GOOGLE") ? getSocialConnectedUsername("GOOGLE").email : isMobile ? "Connect your Account" : "Connect your Google Account"}
										</p>
									</div>
								</div>
								{checkIsSocialConnected("GOOGLE") ? (
									<Button
										label="Connected"
										icon="pi pi-circle-fill"
										className={styles.connectedButton}
										onClick={() => {
											setDisconnectType("GOOGLE");
											setShowDisconnectModal(true);
										}}
									/>
								) : (
									<Button label="Connect" className={styles.connectButton} onClick={() => googleConnect()} />
								)}
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/facebook.png" alt="facebook" className={styles.icon} />
									<div>
										Facebook
										<p className={styles.dotText}>
											{checkIsSocialConnected("FACEBOOK") ? getSocialConnectedUsername("FACEBOOK").email : isMobile ? "Connect your Account" : "Connect your Facebook Account"}
										</p>
									</div>
								</div>
								{checkIsSocialConnected("FACEBOOK") ? (
									<Button
										label="Connected"
										icon="pi pi-circle-fill"
										className={styles.connectedButton}
										onClick={() => {
											setDisconnectType("FACEBOOK");
											setShowDisconnectModal(true);
										}}
									/>
								) : (
									<Button label="Connect" className={styles.connectButton} onClick={() => facebookConnect()} />
								)}
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/apple.png" alt="apple" className={styles.icon} />
									<div>
										Apple
										<p>{isMobile ? "Connect your Account" : "Connect your Apple Account"}</p>
									</div>
								</div>
								<Button label="Coming Soon" className={styles.connectButton} disabled />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/epicgames.png" alt="epic games" className={styles.icon} />
									<div>
										Epic Games
										<p>{isMobile ? "Connect your Account" : "Connect your Epic Games Account"}</p>
									</div>
								</div>
								<Button label="Coming Soon" className={styles.connectButton} disabled />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/steam.png" alt="steam" className={styles.icon} />
									<div>
										Steam
										<p>{isMobile ? "Connect your Account" : "Connect your Steam Account"}</p>
									</div>
								</div>
								<Button label="Coming Soon" className={styles.connectButton} disabled />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/telegram.png" alt="telegram" className={styles.icon} />
									<div>
										Telegram
										<p>{isMobile ? "Connect your Account" : "Connect your Telegram Account"}</p>
									</div>
								</div>
								<Button label="Coming Soon" className={styles.connectButton} disabled />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/x.png" alt="x" className={styles.icon} />
									<div>
										X.com
										<p>{isMobile ? "Connect your Account" : "Connect your X Account"}</p>
									</div>
								</div>
								<Button label="Coming Soon" className={styles.connectButton} disabled />
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className={styles.rightPanel}>
						<h1>
							Connected Socials and Wallets
							<img src="/assets/icons/blue_heading.png" alt="icon" />
						</h1>
						<h2>View and control your linked socials and wallets.</h2>

						<div className={styles.box}>
							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/beyond_white.png" alt="metamask" className={styles.icon} />
									<div>
										Your Email
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
							</div>
						</div>

						<div className={styles.box}>
							<div className={styles.heading}>Connect Wallet</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/walletconnect.png" alt="metamask" className={styles.icon} />
									<div>
										WalletConnect
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>

								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>
						</div>

						<div className={styles.box}>
							<div className={styles.heading}>Connect Socials</div>
							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/google.png" alt="google" className={styles.icon} />
									<div>
										Google
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/facebook.png" alt="facebook" className={styles.icon} />
									<div>
										Facebook
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/apple.png" alt="apple" className={styles.icon} />
									<div>
										Apple
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/epicgames.png" alt="epic games" className={styles.icon} />
									<div>
										Epic Games
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/steam.png" alt="steam" className={styles.icon} />
									<div>
										Steam
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/telegram.png" alt="telegram" className={styles.icon} />
									<div>
										Telegram
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>

							<div className={styles.item}>
								<div className={styles.left}>
									<img src="/assets/icons/social/x.png" alt="x" className={styles.icon} />
									<div>
										X.com
										<Skeleton width="200px" height="20px" className="mt-2" />
									</div>
								</div>
								<Skeleton width="130px" height="40px" className="mt-2" />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
