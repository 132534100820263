import { InputText, Button, Divider, Dialog } from "primereact";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss";
import main from "./main.module.scss";
import { useState } from "react";
import axios from "axios/axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import configuration from "Config";

export default function Auth() {
	const isMobile = window.innerWidth < 768 ? true : false;
	const [showOtherOptions, setShowOtherOptions] = useState(false);
	// const [activeTab, setActiveTab] = useState(0);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const googleLogin = async () => {
		try {
			window.location.replace(
				`https://accounts.google.com/o/oauth2/v2/auth?client_id=${configuration.GOOGLE_CLIENT_ID}&redirect_uri=${configuration.AUTH_REDIRECT_URL}/google&response_type=code&scope=profile email`
			);
		} catch (error) {
			console.error(error);
		}
	};

	const facebookLogin = async () => {
		try {
			window.location.replace(`https://www.facebook.com/v13.0/dialog/oauth?client_id=${configuration.FACEBOOK_CLIENT_ID}&redirect_uri=${configuration.AUTH_REDIRECT_URL}/facebook&scope=email`);
		} catch (error) {
			console.error(error);
		}
	};

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = async (form) => {
		setIsLoading(true);
		try {
			let { response } = await axios.get(`/auth/checkUserByEmail?email=${form.email}`);
			if (response) {
				navigate("/auth/login");
				dispatch({ type: "addNewAuthFlowUserDetails", payload: form });
			} else {
				navigate("/auth/signup");
				dispatch({ type: "addNewAuthFlowUserDetails", payload: form });
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			reset();
		}
	};
	return (
		<>
			<Dialog
				dismissableMask={true}
				contentClassName={styles.contentClass}
				focusOnShow={false}
				headerClassName={styles.headerClass}
				className={styles.showMoreOptionsDialog}
				position="bottom"
				appendTo="self"
				header="Continue With"
				visible={showOtherOptions}
				breakpoints={{ "960px": "75vw", "640px": "100vw" }}
				style={{ width: "26vw" }}
				onHide={() => setShowOtherOptions(false)}
			>
				<div className={styles.tabs}>
					{/* <div className={activeTab === 0 ? `${styles.tab} ${styles.activeTab}` : styles.tab} onClick={() => setActiveTab(0)}>
                        Socials
                    </div> */}
					{/* <div className={activeTab === 1 ? `${styles.tab} ${styles.activeTab}` : styles.tab} onClick={() => setActiveTab(1)}>
                        Wallets
                    </div> */}
				</div>
				<div className={styles.lists}>
					<div className={styles.list} onClick={() => googleLogin()}>
						<img src="/assets/icons/social/google.png" alt="google" />
						Google
					</div>
					<div className={styles.list} onClick={() => facebookLogin()}>
						<img src="/assets/icons/social/facebook.png" alt="facebook" />
						Facebook
					</div>
					<Divider className={styles.divider} />
					<NavLink to="/auth/resetpassword" className={styles.list}>
						<img src="/assets/icons/ph_password-fill.png" alt="reset" />
						Reset your Password
					</NavLink>
				</div>
			</Dialog>

			<div className={`${main.container} ${main.flexCenter}`}>
				<div className={`${main.main} ${main.flexCenter} ${main.autoHeight}`}>
					<div className={styles.header}>
						<img src={isMobile ? "/assets/NavBar/logo.png" : "/assets/icons/logo_plain.png"} alt="logo" className={styles.logo} />
						<h1>
							Log in or sign up{" "}
							{!isMobile && (
								<>
									<br />
									to <span>Beyond</span>
								</>
							)}
						</h1>
					</div>

					<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
						<InputText
							id="email"
							type="email"
							className={errors.email ? `${styles.emailField} ${styles.p_error}` : styles.emailField}
							placeholder="Enter your email address"
							{...register("email", {
								required: {
									value: true,
									message: "Email is required",
								},
								pattern: {
									message: "Invalid email address",
									value: configuration.emailRegex,
								},
							})}
						/>
						{errors.email && <span className={`p-error ${styles.errorClass}`}>{errors.email.message}</span>}
						<Button loading={isLoading} label="Continue" className={styles.continueButton} />
					</form>

					<Divider align="center" className={styles.centerDivider}>
						Or Continue With
					</Divider>

					<div className={styles.socialButtons}>
						<div className={styles.socialButton} onClick={() => googleLogin()}>
							<img src="/assets/icons/social/google.png" alt="google" />
							Google
						</div>
						<div className={styles.socialButton} onClick={() => facebookLogin()}>
							<img src="/assets/icons/social/facebook.png" alt="facebook" />
							Facebook
						</div>
						<div className={`${styles.socialButton} ${styles.moreButton}`} onClick={() => setShowOtherOptions(true)}>
							Show more options
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
